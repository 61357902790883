<template>
  <div
    class="pb-10 mt-20 bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/bg-banner.jpg')"
  >
    <div class="container md:py-10">
      <div class="md:flex md:items-center">
        <div class="md:w-1/2 md:order-2">
          <img
            src="/images/side-img.png"
            alt=""
            class="w-4/5 mx-auto md:w-full lg:w-4/5"
          />
        </div>
        <div class="pt-8 md:w-1/2">
          <p class="pb-4 text-lg font-semibold text-gray-900 lg:text-xl">
            Audit Services
          </p>
          <h1
            class="pb-4 text-4xl font-extrabold leading-none capitalize lg:text-5xl text-green-1"
          >
            Much more than just checking the numbers
          </h1>
          <p class="text-sm text-gray-600 lg:pt-3">
            We believe the statutory audit should be viewed as a chance, not a
            cost. This is your chance to take a look under the hood of your
            business and make sure everything is running as it should – and if
            it’s not, it’s your chance to identify the issues and take steps to
            fix them.
          </p>

          <div v-if="!hideEnquiry" class="mt-4 lg:mt-8">
            <p
              class="py-2 font-semibold text-center text-white transition duration-200 ease-linear transform bg-purple-600 rounded-full w-44 hover:scale-110"
            >
              <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
                >GET A QUOTE</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

