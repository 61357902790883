<template>
  <div>
    <WaBtn />
    <!-- header  -->
    <div
      class="fixed top-0 left-0 w-full py-4 transition duration-200 ease-linear bg-transparent"
      :class="
        fixtop
          ? 'bg-white shadow-md z-10'
          : 'bg-transparent bg-gradient-to-b from-white lg:from '
      "
    >
      <div class="container">
        <div class="flex justify-between md:items-center">
          <img
            src="/images/main-logo-black.png"
            alt=""
            class="h-auto w-52 md:w-72 lg:w-auto"
          />
          <div class="lg:hidden">
            <a href="tel:+60362583692" class="">
              <img
                src="/images/phone.png"
                alt=""
                class="block w-10 transition duration-200 transform hover:scale-110"
              />
              <p></p>
            </a>
          </div>
          <div class="hidden lg:block">
            <p class="text-xl font-semibold text-gray-600">Call Us</p>
            <p
              class="text-2xl font-bold transition duration-200 transform text-green-1 hover:scale-110"
            >
              <a href="tel:+60362583692">
                <i class="fa-solid fa-square-phone"></i> 03-62583692
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- main banner -->
    <MainBanner />

    <div class="py-10 bg-purple-50">
      <div class="container">
        <!-- 1st section -->
        <div class="md:flex md:items-center">
          <div class="md:w-1/2">
            <img src="/images/audit-1.png" alt="" class="w-4/5 mx-auto" />
          </div>
          <div class="pt-4 md:w-1/2 md:pt-0">
            <p
              class="text-2xl font-bold text-purple-700 capitalize lg:text-3xl"
            >
              Purpose of an Audit
            </p>
            <div class="pt-6 space-y-3 text-sm text-gray-600">
              <p>
                FS audit is one of the statutory requirements in the Companies
                Act 2016 to make sure the financial reports are in compliance
                with the approved standards on auditing in Malaysia and
                International Standards on Auditing. Other than that, there are
                numerous of purpose why audit service is needed by a company.
              </p>
              <p>
                Typically, the owners of a company (ie. the shareholders) do not
                closely participate in the day-to-day management of the company.
                The owners employ someone who’s competent to manage the company
                on their behalf and maximise their return on investments.
              </p>
              <p>
                Therefore, auditors are generally appointed by the owners to
                independently examine the financial reports prepared by the
                management. An audit underpins the trust between management and
                owners (as well as other stakeholders – banks, investors,
                suppliers and customers).
              </p>
            </div>
          </div>
        </div>
        <!-- 2nd section -->
        <div class="pt-10">
          <div class="xl:w-3/4">
            <p class="text-sm text-gray-600">
              The
              <span class="font-semibold text-purple-700"
                >primary purpose of a FS audit</span
              >
              is to enhance the degree of confidence in the financial
              statements, so that users can rely on the financial statements to
              make economic decisions. These users may include:
            </p>
          </div>
          <div class="pt-8 md:flex md:flex-wrap xl:max-w-5xl xl:mx-auto">
            <div
              v-for="(item, i) in audit_list"
              :key="i"
              class="flex items-start md:px-1 lg:pb-4 md:w-1/2"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="w-10 h-10 fill-current text-green-1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="w-auto pt-1 pl-2">
                <p class="text-xl font-bold text-purple-700">
                  {{ item.h1 }}
                </p>
                <div class="flex items-start pt-2 text-sm text-gray-600">
                  <p>–</p>
                  <p class="pl-2">{{ item.p }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- benefit of audit  -->
    <div class="py-10 bg-gradient-to-b from-purple-50 to-white">
      <div class="container">
        <div class="md:flex md:items-center xl:max-w-5xl xl:mx-auto">
          <div class="md:w-1/2">
            <p
              class="text-3xl font-bold text-center text-purple-700 xl:text-4xl md:text-left"
            >
              Benefits of an Audit
            </p>
            <div class="pt-6">
              <p>An audit of FS can help a company to:</p>
              <ul
                class="pt-3 pl-4 space-y-2 text-sm text-gray-600 list-disc list-outside"
              >
                <li>
                  Enhance the accuracy and credibility of financial information
                </li>
                <li>
                  Identify weaknesses in internal controls and ways to improve
                  internal controls
                </li>
                <li>
                  Ensure compliance with relevant laws and statutory regulations
                </li>
                <li>
                  Detect and prevent fraud to safeguard shareholders’ interests
                </li>
                <li>
                  Improve your credit rating in obtaining bank loans and dealing
                  with external stakeholders
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-6 md:w-1/2 md:mt-0">
            <img
              src="/images/benefit.png"
              alt=""
              class="w-3/4 mx-auto md:w-full lg:w-3/4 xl:w-auto"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- audit special services  -->
    <div class="py-10">
      <div class="container">
        <div class="">
          <p class="pb-8 text-2xl font-bold text-center text-purple-700">
            The Auditing Specialist
          </p>
          <div class="flex flex-wrap md:justify-center xl:max-w-5xl xl:mx-auto">
            <div
              v-for="(item, i) in audit_specialist"
              :key="i"
              class="w-1/2 p-1 text-center md:w-1/3"
            >
              <div
                class="p-5 text-sm font-semibold leading-tight text-gray-600 transition duration-200 transform bg-white shadow-lg hover:scale-110 rounded-xl"
              >
                <img
                  :src="'/images/' + item.image + '.png'"
                  :alt="item.alt"
                  class="w-20 mx-auto"
                />
                <div class="pt-4">
                  <p class="inline-flex items-center h-16">{{ item.alt }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <p
            class="py-2 mx-auto font-semibold text-center text-white transition duration-200 ease-linear transform bg-purple-600 rounded-full w-44 hover:scale-110"
          >
            <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
              >GET A QUOTE</a
            >
          </p>
        </div>
      </div>
    </div>

    <!-- other services  -->
    <div class="pt-10">
      <p class="text-3xl font-bold text-center text-purple-700">
        Other Services
      </p>
      <div class="pt-8">
        <hooper :settings="hooperSettings" pagination="no">
          <slide
            v-for="(item, indx) in slides"
            :key="indx"
            :index="indx"
            class="relative"
          >
            <img :src="'/images/' + item.image + '.jpg'" :alt="item.alt" />
            <div
              class="absolute bottom-0 w-full text-center bg-gradient-to-t from-purple-600 to-transparent h-1/2"
            >
              <p
                class="absolute left-0 w-full py-2 text-lg font-bold text-white bottom-2"
              >
                {{ item.alt }}
              </p>
            </div>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
    </div>

    <!--  free analysis -->
    <div
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-financial.jpg')"
    >
      <div class="container py-20">
        <div>
          <p class="text-3xl font-extrabold text-white lg:text-4xl">
            FREE <span class="text-purple-700">ANALYSIS REPORT</span> &
            RECOMMENDATION
          </p>
          <p class="pt-3 text-sm text-gray-50">
            If you have an existing financial account, we can perform an
            analysis on your account and give you a recommendation
            <br class="hidden lg:block" />
            on how can you improve your account for better compliance and
            accuracy.
          </p>
          <div class="mt-10">
            <p
              class="py-2 font-semibold text-center text-white transition duration-200 ease-linear transform bg-purple-600 rounded-full w-44 hover:scale-110"
            >
              <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
                >GET A QUOTE</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10 bg-bottom bg-no-repeat bg-cover">
      <div class="container">
        <div class="shadow-lg lg:flex xl:max-w-5xl xl:mx-auto">
          <div
            class="lg:w-2/3 lg:bg-cover lg:bg-center lg:bg-no-repeat"
            style="background-image: url('/images/about.jpg')"
          >
            <img src="/images/about.jpg" alt="" class="lg:hidden" />
          </div>
          <div class="p-4 bg-purple-600 lg:py-4 lg:w-1/3">
            <p class="text-xl font-bold text-white lg:text-2xl">About Us</p>
            <div class="pt-2 space-y-3 text-sm text-gray-50">
              <p>
                <strong>KS Chia & Associates</strong> is an audit firm
                registered with Malaysian Institutes of Accountants (MIA) with
                firm No. AF 001828. Founded in 2003, the sole-proprietor firm
                initially registered as a non-audit firm and successfully
                converted into an audit firm in January 2006.
              </p>
              <p>
                The firm and its affiliated company provide a wide spectrum of
                high standard professional services including
                <strong
                  >auditing, accountancy, investigations, corporate and personal
                  taxation, tax consultancy services, corporate secretarial
                  services and management advisory services in general.</strong
                >
                We aim to provide “one-stop” shop professional services for our
                clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer  -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-about.jpg')"
    >
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="pb-10 lg:w-1/2 lg:pb-0 lg:pr-4">
            <p class="text-3xl font-extrabold text-purple-700">
              Trusted Malaysia Auditors
            </p>
            <div class="mt-5 text-base">
              <p class="text-gray-600">
                You are welcome to call us for advice <br />
                <span class="text-lg font-semibold text-green-1"
                  >Call Us Now <a href="tel:+60362583692">03-62583692</a></span
                >
              </p>
            </div>
            <div class="pt-8">
              <p class="text-xl font-bold text-purple-700">Locate Us</p>
              <p class="pt-3 pb-6 text-sm text-gray-600">
                No. 9-3A-1, Jalan Metro Perdana Barat 1, Taman Usahawan Kepong
                Utara, 52100 Kuala Lumpur.
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7967.078984533752!2d101.641441!3d3.214825!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9b0d6c141e2c9fe1!2sKS%20Chia%20%26%20Associates%20(Chartered%20Accountants)%20(AF001828)%20-%20Audit%20Firm%20Malaysia!5e0!3m2!1sen!2smy!4v1673495068085!5m2!1sen!2smy"
                width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div id="element" class="pt-4 lg:px-2 lg:w-1/2">
            <!-- enquiry form  -->
            <div class="p-6 bg-white rounded-xl lg:shadow-md">
              <h1
                class="pb-3 text-3xl font-bold leading-tight text-gray-700 capitalize lg:text-4xl"
              >
                What Can <br class="lg:hidden" />
                <span class="text-green-1">We Help You?</span>
              </h1>
              <!-- <EnquiryForm class="pt-8" /> -->
              <!-- feedback.activamedia.com.sg script begins here --><iframe
                allowTransparency="true"
                style="min-height: 580px; height: inherit; overflow: auto"
                width="100%"
                id="contactform123"
                name="contactform123"
                marginwidth="0"
                marginheight="0"
                frameborder="0"
                src="https://feedback.activamedia.com.sg/my-contact-form-5624150.html"
                ><p>
                  Your browser does not support iframes. The contact form cannot
                  be displayed. Please use another contact method (phone, fax
                  etc)
                </p></iframe
              ><!-- feedback.activamedia.com.sg script ends here -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-3 bg-gray-700">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue";
import WaBtn from "@/components/WaBtn.vue";

export default {
  name: "Home",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    EnquiryForm,
    MainBanner,
    WaBtn,
  },
  data() {
    return {
      fixtop: false,
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        wheelControl: false,
        autoPlay: true,
        infiniteScroll: true,
        playSpeed: 4500,
        hoverPause: false,
        breakpoints: {
          768: {
            centerMode: false,
            itemsToShow: 2,
          },
          1024: {
            centerMode: false,
            itemsToShow: 3,
          },
          1500: {
            centerMode: false,
            itemsToShow: 4,
          },
        },
      },
      audit_list: [
        {
          h1: "Existing Shareholders ",
          p: "to assess the risk and return of their investment",
        },
        {
          h1: "Suppliers / Banks / Lenders",
          p: "to assess your business’s credit worthiness in deciding whether to extend a loan or credit",
        },
        {
          h1: "Potential Investors",
          p: "to assess your business value and profitability",
        },
        {
          h1: "Government Agencies",
          p: "to assess whether your business meets the conditions attached to the government grants/subsidies",
        },
        {
          h1: "Customers",
          p: "to assess your business’s financial health and stability to ensure a steady supply",
        },
        {
          h1: "Tax Authority",
          p: "to review whether the tax declared is correct",
        },
      ],
      audit_specialist: [
        {
          image: "icon-1",
          alt: "Annual Statutory Audit",
        },
        {
          image: "icon-2",
          alt: "Audit of MFRS / MPERS Financial Statements",
        },
        {
          image: "icon-3",
          alt: "Special Review of Financial Statements",
        },
        {
          image: "icon-4",
          alt: "Agreed Upon Procedures Engagement",
        },
        {
          image: "icon-5",
          alt: "Compilation of Unaudited Financial Statements",
        },
      ],
      slides: [
        {
          image: "product-1",
          alt: "Tax Consultancy Services",
        },
        {
          image: "product-2",
          alt: "SST Consultancy Services",
        },
        {
          image: "product-3",
          alt: "Corporate Secretarial Services",
        },
        {
          image: "product-4",
          alt: "Accounting Services",
        },
        {
          image: "product-5",
          alt: "Payroll Services",
        },
      ],
    };
  },
  methods: {
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>
<style>
.hooper {
  height: auto !important;
}
.hooper-next,
.hooper-prev {
  background-color: rgba(255, 255, 255, 0.623);
  border: none;
  padding: 1em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
